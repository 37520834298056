@mixin prototype-spacing {
  @include breakpoint(large) {
    @for $spacer from 0 through $prototype-spacers-count {
      // All Sides
      .large-margin-#{$spacer} {
        @include margin($spacer, $spacer, $spacer, $spacer);
      }

      .large-padding-#{$spacer} {
        @include padding($spacer, $spacer, $spacer, $spacer);
      }

      // Top Side
      .large-margin-top-#{$spacer} {
        @include margin($spacer, null, null, null);
      }

      .large-padding-top-#{$spacer} {
        @include padding($spacer, null, null, null);
      }

      // Right Side
      .large-margin-right-#{$spacer} {
        @include margin(null, $spacer, null, null);
      }

      .large-padding-right-#{$spacer} {
        @include padding(null, $spacer, null, null);
      }

      // Bottom Side
      .large-margin-bottom-#{$spacer} {
        @include margin(null, null, $spacer, null);
      }

      .large-padding-bottom-#{$spacer} {
        @include padding(null, null, $spacer, null);
      }

      // Left Side
      .large-margin-left-#{$spacer} {
        @include margin(null, null, null, $spacer);
      }

      .large-padding-left-#{$spacer} {
        @include padding(null, null, null, $spacer);
      }

      // Horizontal Axes
      .large-margin-horizontal-#{$spacer} {
        @include margin(null, $spacer, null, $spacer);
      }

      .large-padding-horizontal-#{$spacer} {
        @include padding(null, $spacer, null, $spacer);
      }

      // Vertical Axes
      .large-margin-vertical-#{$spacer} {
        @include margin($spacer, null, $spacer, null)
      }

      .large-padding-vertical-#{$spacer} {
        @include padding($spacer, null, $spacer, null)
      }

      @if ($prototype-spacing-breakpoints) {
        // Loop through Responsive Breakpoints
        @each $size in $breakpoint-classes {
          @include breakpoint($size) {
            @if $size != $-zf-zero-breakpoint {
              // All Sides
              .#{$size}-margin-#{$spacer} {
                @include margin($spacer, $spacer, $spacer, $spacer);
              }

              .#{$size}-padding-#{$spacer} {
                @include padding($spacer, $spacer, $spacer, $spacer);
              }

              // Top Side
              .#{$size}-margin-top-#{$spacer} {
                @include margin($spacer, null, null, null);
              }

              .#{$size}-padding-top-#{$spacer} {
                @include padding($spacer, null, null, null);
              }

              // Right Side
              .#{$size}-margin-right-#{$spacer} {
                @include margin(null, $spacer, null, null);
              }

              .#{$size}-padding-right-#{$spacer} {
                @include padding(null, $spacer, null, null);
              }

              // Bottom Side
              .#{$size}-margin-bottom-#{$spacer} {
                @include margin(null, null, $spacer, null);
              }

              .#{$size}-padding-bottom-#{$spacer} {
                @include padding(null, null, $spacer, null);
              }

              // Left Side
              .#{$size}-margin-left-#{$spacer} {
                @include margin(null, null, null, $spacer);
              }

              .#{$size}-padding-left-#{$spacer} {
                @include padding(null, null, null, $spacer);
              }

              // Horizontal Axes
              .#{$size}-margin-horizontal-#{$spacer} {
                @include margin(null, $spacer, null, $spacer);
              }

              .#{$size}-padding-horizontal-#{$spacer} {
                @include padding(null, $spacer, null, $spacer);
              }

              // Vertical Axes
              .#{$size}-margin-vertical-#{$spacer} {
                @include margin($spacer, null, $spacer, null)
              }

              .#{$size}-padding-vertical-#{$spacer} {
                @include padding($spacer, null, $spacer, null)
              }
            }
          }
        }
      }
    }
  }

  @include breakpoint(large only) {
    @for $spacer from 0 through $prototype-spacers-count {
      // All Sides
      .large-only-margin-#{$spacer} {
        @include margin($spacer, $spacer, $spacer, $spacer);
      }

      .large-only-padding-#{$spacer} {
        @include padding($spacer, $spacer, $spacer, $spacer);
      }

      // Top Side
      .large-only-margin-top-#{$spacer} {
        @include margin($spacer, null, null, null);
      }

      .large-only-padding-top-#{$spacer} {
        @include padding($spacer, null, null, null);
      }

      // Right Side
      .large-only-margin-right-#{$spacer} {
        @include margin(null, $spacer, null, null);
      }

      .large-only-padding-right-#{$spacer} {
        @include padding(null, $spacer, null, null);
      }

      // Bottom Side
      .large-only-margin-bottom-#{$spacer} {
        @include margin(null, null, $spacer, null);
      }

      .large-only-padding-bottom-#{$spacer} {
        @include padding(null, null, $spacer, null);
      }

      // Left Side
      .large-only-margin-left-#{$spacer} {
        @include margin(null, null, null, $spacer);
      }

      .large-only-padding-left-#{$spacer} {
        @include padding(null, null, null, $spacer);
      }

      // Horizontal Axes
      .large-only-margin-horizontal-#{$spacer} {
        @include margin(null, $spacer, null, $spacer);
      }

      .large-only-padding-horizontal-#{$spacer} {
        @include padding(null, $spacer, null, $spacer);
      }

      // Vertical Axes
      .large-only-margin-vertical-#{$spacer} {
        @include margin($spacer, null, $spacer, null)
      }

      .large-only-padding-vertical-#{$spacer} {
        @include padding($spacer, null, $spacer, null)
      }

      @if ($prototype-spacing-breakpoints) {
        // Loop through Responsive Breakpoints
        @each $size in $breakpoint-classes {
          @include breakpoint($size) {
            @if $size != $-zf-zero-breakpoint {
              // All Sides
              .#{$size}-margin-#{$spacer} {
                @include margin($spacer, $spacer, $spacer, $spacer);
              }

              .#{$size}-padding-#{$spacer} {
                @include padding($spacer, $spacer, $spacer, $spacer);
              }

              // Top Side
              .#{$size}-margin-top-#{$spacer} {
                @include margin($spacer, null, null, null);
              }

              .#{$size}-padding-top-#{$spacer} {
                @include padding($spacer, null, null, null);
              }

              // Right Side
              .#{$size}-margin-right-#{$spacer} {
                @include margin(null, $spacer, null, null);
              }

              .#{$size}-padding-right-#{$spacer} {
                @include padding(null, $spacer, null, null);
              }

              // Bottom Side
              .#{$size}-margin-bottom-#{$spacer} {
                @include margin(null, null, $spacer, null);
              }

              .#{$size}-padding-bottom-#{$spacer} {
                @include padding(null, null, $spacer, null);
              }

              // Left Side
              .#{$size}-margin-left-#{$spacer} {
                @include margin(null, null, null, $spacer);
              }

              .#{$size}-padding-left-#{$spacer} {
                @include padding(null, null, null, $spacer);
              }

              // Horizontal Axes
              .#{$size}-margin-horizontal-#{$spacer} {
                @include margin(null, $spacer, null, $spacer);
              }

              .#{$size}-padding-horizontal-#{$spacer} {
                @include padding(null, $spacer, null, $spacer);
              }

              // Vertical Axes
              .#{$size}-margin-vertical-#{$spacer} {
                @include margin($spacer, null, $spacer, null)
              }

              .#{$size}-padding-vertical-#{$spacer} {
                @include padding($spacer, null, $spacer, null)
              }
            }
          }
        }
      }
    }
  }

  @include breakpoint(medium) {
    @for $spacer from 0 through $prototype-spacers-count {
      // All Sides
      .medium-margin-#{$spacer} {
        @include margin($spacer, $spacer, $spacer, $spacer);
      }

      .medium-padding-#{$spacer} {
        @include padding($spacer, $spacer, $spacer, $spacer);
      }

      // Top Side
      .medium-margin-top-#{$spacer} {
        @include margin($spacer, null, null, null);
      }

      .medium-padding-top-#{$spacer} {
        @include padding($spacer, null, null, null);
      }

      // Right Side
      .medium-margin-right-#{$spacer} {
        @include margin(null, $spacer, null, null);
      }

      .medium-padding-right-#{$spacer} {
        @include padding(null, $spacer, null, null);
      }

      // Bottom Side
      .medium-margin-bottom-#{$spacer} {
        @include margin(null, null, $spacer, null);
      }

      .medium-padding-bottom-#{$spacer} {
        @include padding(null, null, $spacer, null);
      }

      // Left Side
      .medium-margin-left-#{$spacer} {
        @include margin(null, null, null, $spacer);
      }

      .medium-padding-left-#{$spacer} {
        @include padding(null, null, null, $spacer);
      }

      // Horizontal Axes
      .medium-margin-horizontal-#{$spacer} {
        @include margin(null, $spacer, null, $spacer);
      }

      .medium-padding-horizontal-#{$spacer} {
        @include padding(null, $spacer, null, $spacer);
      }

      // Vertical Axes
      .medium-margin-vertical-#{$spacer} {
        @include margin($spacer, null, $spacer, null)
      }

      .medium-padding-vertical-#{$spacer} {
        @include padding($spacer, null, $spacer, null)
      }

      @if ($prototype-spacing-breakpoints) {
        // Loop through Responsive Breakpoints
        @each $size in $breakpoint-classes {
          @include breakpoint($size) {
            @if $size != $-zf-zero-breakpoint {
              // All Sides
              .#{$size}-margin-#{$spacer} {
                @include margin($spacer, $spacer, $spacer, $spacer);
              }

              .#{$size}-padding-#{$spacer} {
                @include padding($spacer, $spacer, $spacer, $spacer);
              }

              // Top Side
              .#{$size}-margin-top-#{$spacer} {
                @include margin($spacer, null, null, null);
              }

              .#{$size}-padding-top-#{$spacer} {
                @include padding($spacer, null, null, null);
              }

              // Right Side
              .#{$size}-margin-right-#{$spacer} {
                @include margin(null, $spacer, null, null);
              }

              .#{$size}-padding-right-#{$spacer} {
                @include padding(null, $spacer, null, null);
              }

              // Bottom Side
              .#{$size}-margin-bottom-#{$spacer} {
                @include margin(null, null, $spacer, null);
              }

              .#{$size}-padding-bottom-#{$spacer} {
                @include padding(null, null, $spacer, null);
              }

              // Left Side
              .#{$size}-margin-left-#{$spacer} {
                @include margin(null, null, null, $spacer);
              }

              .#{$size}-padding-left-#{$spacer} {
                @include padding(null, null, null, $spacer);
              }

              // Horizontal Axes
              .#{$size}-margin-horizontal-#{$spacer} {
                @include margin(null, $spacer, null, $spacer);
              }

              .#{$size}-padding-horizontal-#{$spacer} {
                @include padding(null, $spacer, null, $spacer);
              }

              // Vertical Axes
              .#{$size}-margin-vertical-#{$spacer} {
                @include margin($spacer, null, $spacer, null)
              }

              .#{$size}-padding-vertical-#{$spacer} {
                @include padding($spacer, null, $spacer, null)
              }
            }
          }
        }
      }
    }
  }

  @include breakpoint(medium only) {
    @for $spacer from 0 through $prototype-spacers-count {
      // All Sides
      .medium-only-margin-#{$spacer} {
        @include margin($spacer, $spacer, $spacer, $spacer);
      }

      .medium-only-padding-#{$spacer} {
        @include padding($spacer, $spacer, $spacer, $spacer);
      }

      // Top Side
      .medium-only-margin-top-#{$spacer} {
        @include margin($spacer, null, null, null);
      }

      .medium-only-padding-top-#{$spacer} {
        @include padding($spacer, null, null, null);
      }

      // Right Side
      .medium-only-margin-right-#{$spacer} {
        @include margin(null, $spacer, null, null);
      }

      .medium-only-padding-right-#{$spacer} {
        @include padding(null, $spacer, null, null);
      }

      // Bottom Side
      .medium-only-margin-bottom-#{$spacer} {
        @include margin(null, null, $spacer, null);
      }

      .medium-only-padding-bottom-#{$spacer} {
        @include padding(null, null, $spacer, null);
      }

      // Left Side
      .medium-only-margin-left-#{$spacer} {
        @include margin(null, null, null, $spacer);
      }

      .medium-only-padding-left-#{$spacer} {
        @include padding(null, null, null, $spacer);
      }

      // Horizontal Axes
      .medium-only-margin-horizontal-#{$spacer} {
        @include margin(null, $spacer, null, $spacer);
      }

      .medium-only-padding-horizontal-#{$spacer} {
        @include padding(null, $spacer, null, $spacer);
      }

      // Vertical Axes
      .medium-only-margin-vertical-#{$spacer} {
        @include margin($spacer, null, $spacer, null)
      }

      .medium-only-padding-vertical-#{$spacer} {
        @include padding($spacer, null, $spacer, null)
      }

      @if ($prototype-spacing-breakpoints) {
        // Loop through Responsive Breakpoints
        @each $size in $breakpoint-classes {
          @include breakpoint($size) {
            @if $size != $-zf-zero-breakpoint {
              // All Sides
              .#{$size}-margin-#{$spacer} {
                @include margin($spacer, $spacer, $spacer, $spacer);
              }

              .#{$size}-padding-#{$spacer} {
                @include padding($spacer, $spacer, $spacer, $spacer);
              }

              // Top Side
              .#{$size}-margin-top-#{$spacer} {
                @include margin($spacer, null, null, null);
              }

              .#{$size}-padding-top-#{$spacer} {
                @include padding($spacer, null, null, null);
              }

              // Right Side
              .#{$size}-margin-right-#{$spacer} {
                @include margin(null, $spacer, null, null);
              }

              .#{$size}-padding-right-#{$spacer} {
                @include padding(null, $spacer, null, null);
              }

              // Bottom Side
              .#{$size}-margin-bottom-#{$spacer} {
                @include margin(null, null, $spacer, null);
              }

              .#{$size}-padding-bottom-#{$spacer} {
                @include padding(null, null, $spacer, null);
              }

              // Left Side
              .#{$size}-margin-left-#{$spacer} {
                @include margin(null, null, null, $spacer);
              }

              .#{$size}-padding-left-#{$spacer} {
                @include padding(null, null, null, $spacer);
              }

              // Horizontal Axes
              .#{$size}-margin-horizontal-#{$spacer} {
                @include margin(null, $spacer, null, $spacer);
              }

              .#{$size}-padding-horizontal-#{$spacer} {
                @include padding(null, $spacer, null, $spacer);
              }

              // Vertical Axes
              .#{$size}-margin-vertical-#{$spacer} {
                @include margin($spacer, null, $spacer, null)
              }

              .#{$size}-padding-vertical-#{$spacer} {
                @include padding($spacer, null, $spacer, null)
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 39.938em) {
    @for $spacer from 0 through $prototype-spacers-count {
      // All Sides
      .small-margin-#{$spacer} {
        @include margin($spacer, $spacer, $spacer, $spacer);
      }

      .small-padding-#{$spacer} {
        @include padding($spacer, $spacer, $spacer, $spacer);
      }

      // Top Side
      .small-margin-top-#{$spacer} {
        @include margin($spacer, null, null, null);
      }

      .small-padding-top-#{$spacer} {
        @include padding($spacer, null, null, null);
      }

      // Right Side
      .small-margin-right-#{$spacer} {
        @include margin(null, $spacer, null, null);
      }

      .small-padding-right-#{$spacer} {
        @include padding(null, $spacer, null, null);
      }

      // Bottom Side
      .small-margin-bottom-#{$spacer} {
        @include margin(null, null, $spacer, null);
      }

      .small-padding-bottom-#{$spacer} {
        @include padding(null, null, $spacer, null);
      }

      // Left Side
      .small-margin-left-#{$spacer} {
        @include margin(null, null, null, $spacer);
      }

      .small-padding-left-#{$spacer} {
        @include padding(null, null, null, $spacer);
      }

      // Horizontal Axes
      .small-margin-horizontal-#{$spacer} {
        @include margin(null, $spacer, null, $spacer);
      }

      .small-padding-horizontal-#{$spacer} {
        @include padding(null, $spacer, null, $spacer);
      }

      // Vertical Axes
      .small-margin-vertical-#{$spacer} {
        @include margin($spacer, null, $spacer, null)
      }

      .small-padding-vertical-#{$spacer} {
        @include padding($spacer, null, $spacer, null)
      }

      @if ($prototype-spacing-breakpoints) {
        // Loop through Responsive Breakpoints
        @each $size in $breakpoint-classes {
          @include breakpoint($size) {
            @if $size != $-zf-zero-breakpoint {
              // All Sides
              .#{$size}-margin-#{$spacer} {
                @include margin($spacer, $spacer, $spacer, $spacer);
              }

              .#{$size}-padding-#{$spacer} {
                @include padding($spacer, $spacer, $spacer, $spacer);
              }

              // Top Side
              .#{$size}-margin-top-#{$spacer} {
                @include margin($spacer, null, null, null);
              }

              .#{$size}-padding-top-#{$spacer} {
                @include padding($spacer, null, null, null);
              }

              // Right Side
              .#{$size}-margin-right-#{$spacer} {
                @include margin(null, $spacer, null, null);
              }

              .#{$size}-padding-right-#{$spacer} {
                @include padding(null, $spacer, null, null);
              }

              // Bottom Side
              .#{$size}-margin-bottom-#{$spacer} {
                @include margin(null, null, $spacer, null);
              }

              .#{$size}-padding-bottom-#{$spacer} {
                @include padding(null, null, $spacer, null);
              }

              // Left Side
              .#{$size}-margin-left-#{$spacer} {
                @include margin(null, null, null, $spacer);
              }

              .#{$size}-padding-left-#{$spacer} {
                @include padding(null, null, null, $spacer);
              }

              // Horizontal Axes
              .#{$size}-margin-horizontal-#{$spacer} {
                @include margin(null, $spacer, null, $spacer);
              }

              .#{$size}-padding-horizontal-#{$spacer} {
                @include padding(null, $spacer, null, $spacer);
              }

              // Vertical Axes
              .#{$size}-margin-vertical-#{$spacer} {
                @include margin($spacer, null, $spacer, null)
              }

              .#{$size}-padding-vertical-#{$spacer} {
                @include padding($spacer, null, $spacer, null)
              }
            }
          }
        }
      }
    }
  }

  @include breakpoint(small only) {
    @for $spacer from 0 through $prototype-spacers-count {
      // All Sides
      .small-only-margin-#{$spacer} {
        @include margin($spacer, $spacer, $spacer, $spacer);
      }

      .small-only-padding-#{$spacer} {
        @include padding($spacer, $spacer, $spacer, $spacer);
      }

      // Top Side
      .small-only-margin-top-#{$spacer} {
        @include margin($spacer, null, null, null);
      }

      .small-only-padding-top-#{$spacer} {
        @include padding($spacer, null, null, null);
      }

      // Right Side
      .small-only-margin-right-#{$spacer} {
        @include margin(null, $spacer, null, null);
      }

      .small-only-padding-right-#{$spacer} {
        @include padding(null, $spacer, null, null);
      }

      // Bottom Side
      .small-only-margin-bottom-#{$spacer} {
        @include margin(null, null, $spacer, null);
      }

      .small-only-padding-bottom-#{$spacer} {
        @include padding(null, null, $spacer, null);
      }

      // Left Side
      .small-only-margin-left-#{$spacer} {
        @include margin(null, null, null, $spacer);
      }

      .small-only-padding-left-#{$spacer} {
        @include padding(null, null, null, $spacer);
      }

      // Horizontal Axes
      .small-only-margin-horizontal-#{$spacer} {
        @include margin(null, $spacer, null, $spacer);
      }

      .small-only-padding-horizontal-#{$spacer} {
        @include padding(null, $spacer, null, $spacer);
      }

      // Vertical Axes
      .small-only-margin-vertical-#{$spacer} {
        @include margin($spacer, null, $spacer, null)
      }

      .small-only-padding-vertical-#{$spacer} {
        @include padding($spacer, null, $spacer, null)
      }

      @if ($prototype-spacing-breakpoints) {
        // Loop through Responsive Breakpoints
        @each $size in $breakpoint-classes {
          @include breakpoint($size) {
            @if $size != $-zf-zero-breakpoint {
              // All Sides
              .#{$size}-margin-#{$spacer} {
                @include margin($spacer, $spacer, $spacer, $spacer);
              }

              .#{$size}-padding-#{$spacer} {
                @include padding($spacer, $spacer, $spacer, $spacer);
              }

              // Top Side
              .#{$size}-margin-top-#{$spacer} {
                @include margin($spacer, null, null, null);
              }

              .#{$size}-padding-top-#{$spacer} {
                @include padding($spacer, null, null, null);
              }

              // Right Side
              .#{$size}-margin-right-#{$spacer} {
                @include margin(null, $spacer, null, null);
              }

              .#{$size}-padding-right-#{$spacer} {
                @include padding(null, $spacer, null, null);
              }

              // Bottom Side
              .#{$size}-margin-bottom-#{$spacer} {
                @include margin(null, null, $spacer, null);
              }

              .#{$size}-padding-bottom-#{$spacer} {
                @include padding(null, null, $spacer, null);
              }

              // Left Side
              .#{$size}-margin-left-#{$spacer} {
                @include margin(null, null, null, $spacer);
              }

              .#{$size}-padding-left-#{$spacer} {
                @include padding(null, null, null, $spacer);
              }

              // Horizontal Axes
              .#{$size}-margin-horizontal-#{$spacer} {
                @include margin(null, $spacer, null, $spacer);
              }

              .#{$size}-padding-horizontal-#{$spacer} {
                @include padding(null, $spacer, null, $spacer);
              }

              // Vertical Axes
              .#{$size}-margin-vertical-#{$spacer} {
                @include margin($spacer, null, $spacer, null)
              }

              .#{$size}-padding-vertical-#{$spacer} {
                @include padding($spacer, null, $spacer, null)
              }
            }
          }
        }
      }
    }
  }
}