.articles {
  .article-content {
    a {
      h4 {
        color: #181b1d;
      }

      &:hover h4 {
        color: #58ac2e;
      }
    }

    .image-container {
      max-width: 100%;
      max-height: 280px;
      overflow: hidden;
      background: #f3f3f3;

      img {
        display: block;
        max-width: 100%;
        min-height: 280px;
        margin: auto;
        overflow: auto;
      }
    }

    p:only-child,
    p:last-child {
      margin-bottom: 0;
    }
  }

  .article-pagination {
    text-align: center;

    a {
      color: #4e4e4e;
    }

    .pagination .current {
      background: #181b1d; // 58ac2e
    }

    .pagination a:hover,
    .pagination button:hover {
      background: #181b1d; //6fcc41
      color: #fefefe;
    }
  }
}
