.banner-content-wrapper {
  position: relative;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  width: 100%;
  height: 400px;

  @include breakpoint(medium down) {
    height: 300px;
  }

  @media screen and (max-width: 39.938em) {
    height: 200px;
  }

  .banner-content {
    height: inherit;

    &.banner-content-background:before {
      position: absolute;
      background: rgba(0, 0, 0, 0.5);
      content: '';
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      max-width: 75%;

        @include breakpoint(medium down) {
          max-width: 100%;
        }
    }

    .banner-content-inner {
      position: relative;
      top: 50%;
      transform: translateY(-50%);

      .row {
        max-width: 90rem;

        h1,
        h2 {
          line-height: 1;
        }

        p {
          font-size: 20px;
        }
      }
    }
  }
}