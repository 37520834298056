@mixin border(
  $top: null,
  $right: null,
  $bottom: null,
  $left: null
) {
  @if $top == null and $right == null and $bottom == null and $left == null {
    border-top: 0 !important;
    border-right: 0 !important;
    border-bottom: 0 !important;
    border-left: 0 !important;
  } @else {
    @if $top != null {
      border-top: $top * $global-border solid $global-border-color !important;
    }
    @if $right != null {
      border-right: $right * $global-border solid $global-border-color !important;
    }
    @if $bottom != null {
      border-bottom: $bottom * $global-border solid $global-border-color !important;
    }
    @if $left != null {
      border-left: $left * $global-border solid $global-border-color !important;
    }
  }
}

@mixin prototype-borders {
  @for $border from 0 through $prototype-borders-count {
    .border-#{$border} {
      @include border($border, $border, $border, $border);
    }

    .border-top-#{$border} {
      @include border($border, null, null, null);
    }

    .border-bottom-#{$border} {
      @include border(null, null, $border, null);
    }

    .border-left-#{$border} {
      @include border(null, null, null, $border);
    }

    .border-right-#{$border} {
      @include border(null, $border, null, null);
    }

    .border-vertical-#{$border} {
      @include border($border, null, $border, null);
    }

    .border-horizontal-#{$border} {
      @include border(null, $border, null, $border);
    }
  }

  @include breakpoint(large) {
    @for $border from 0 through $prototype-borders-count {
      .large-border-#{$border} {
        @include border($border, $border, $border, $border);
      }

      .large-border-top-#{$border} {
        @include border($border, null, null, null);
      }

      .large-border-bottom-#{$border} {
        @include border(null, null, $border, null);
      }

      .large-border-left-#{$border} {
        @include border(null, null, null, $border);
      }

      .large-border-right-#{$border} {
        @include border(null, $border, null, null);
      }

      .large-border-vertical-#{$border} {
        @include border($border, null, $border, null);
      }

      .large-border-horizontal-#{$border} {
        @include border(null, $border, null, $border);
      }
    }
  }

  @include breakpoint(large only) {
    @for $border from 0 through $prototype-borders-count {
      .large-only-border-#{$border} {
        @include border($border, $border, $border, $border);
      }

      .large-only-border-top-#{$border} {
        @include border($border, null, null, null);
      }

      .large-only-border-bottom-#{$border} {
        @include border(null, null, $border, null);
      }

      .large-only-border-left-#{$border} {
        @include border(null, null, null, $border);
      }

      .large-only-border-right-#{$border} {
        @include border(null, $border, null, null);
      }

      .large-only-border-vertical-#{$border} {
        @include border($border, null, $border, null);
      }

      .large-only-border-horizontal-#{$border} {
        @include border(null, $border, null, $border);
      }
    }
  }

  @include breakpoint(medium) {
    @for $border from 0 through $prototype-borders-count {
      .medium-border-#{$border} {
        @include border($border, $border, $border, $border);
      }

      .medium-border-top-#{$border} {
        @include border($border, null, null, null);
      }

      .medium-border-bottom-#{$border} {
        @include border(null, null, $border, null);
      }

      .medium-border-left-#{$border} {
        @include border(null, null, null, $border);
      }

      .medium-border-right-#{$border} {
        @include border(null, $border, null, null);
      }

      .medium-border-vertical-#{$border} {
        @include border($border, null, $border, null);
      }

      .medium-border-horizontal-#{$border} {
        @include border(null, $border, null, $border);
      }
    }
  }

  @include breakpoint(medium only) {
    @for $border from 0 through $prototype-borders-count {
      .medium-only-border-#{$border} {
        @include border($border, $border, $border, $border);
      }

      .medium-only-border-top-#{$border} {
        @include border($border, null, null, null);
      }

      .medium-only-border-bottom-#{$border} {
        @include border(null, null, $border, null);
      }

      .medium-only-border-left-#{$border} {
        @include border(null, null, null, $border);
      }

      .medium-only-border-right-#{$border} {
        @include border(null, $border, null, null);
      }

      .medium-only-border-vertical-#{$border} {
        @include border($border, null, $border, null);
      }

      .medium-only-border-horizontal-#{$border} {
        @include border(null, $border, null, $border);
      }
    }
  }

  @media screen and (max-width: 39.938em) {
    @for $border from 0 through $prototype-borders-count {
      .small-border-#{$border} {
        @include border($border, $border, $border, $border);
      }

      .small-border-top-#{$border} {
        @include border($border, null, null, null);
      }

      .small-border-bottom-#{$border} {
        @include border(null, null, $border, null);
      }

      .small-border-left-#{$border} {
        @include border(null, null, null, $border);
      }

      .small-border-right-#{$border} {
        @include border(null, $border, null, null);
      }

      .small-border-vertical-#{$border} {
        @include border($border, null, $border, null);
      }

      .small-border-horizontal-#{$border} {
        @include border(null, $border, null, $border);
      }
    }
  }

  @include breakpoint(small only) {
    @for $border from 0 through $prototype-borders-count {
      .small-only-border-#{$border} {
        @include border($border, $border, $border, $border);
      }

      .small-only-border-top-#{$border} {
        @include border($border, null, null, null);
      }

      .small-only-border-bottom-#{$border} {
        @include border(null, null, $border, null);
      }

      .small-only-border-left-#{$border} {
        @include border(null, null, null, $border);
      }

      .small-only-border-right-#{$border} {
        @include border(null, $border, null, null);
      }

      .small-only-border-vertical-#{$border} {
        @include border($border, null, $border, null);
      }

      .small-only-border-horizontal-#{$border} {
        @include border(null, $border, null, $border);
      }
    }
  }
}