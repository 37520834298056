@font-face {
  font-family: 'Gotham Pro';
  font-style: normal;
  font-weight: normal;
  src: local('Gotham Pro'), url(../fonts/GothaProReg.woff) format('woff');
}

@font-face {
  font-family: 'Gotham Pro Medium';
  font-style: normal;
  font-weight: normal;
  src: local('Gotham Pro Medium'), url(../fonts/GothaProMed.woff) format('woff');
}

@font-face {
  font-family: "SSGizmo";
  src: url(../fonts/ss-gizmo.ttf) format('truetype');
  font-weight: normal;
  font-style: normal
}