.option-buttons {
  margin: -30px;

  @media screen and (max-width: 63.938em) {
    margin: -20px;
  }

  @media screen and (max-width: 39.938em) {
    margin: -10px;
  }

  a,
  a:link,
  a:active,
  a:visited,
  a:hover,
  a:focus {
    color: #fefefe;
  }

  .button {
    padding: 30px 80px;

    @media screen and (max-width: 89.938em) {
      padding: 20px 40px;
    }

    @media screen and (max-width: 39.938em) {
      padding: 20px;
    }

    .button-content {
      border: 2px solid #fefefe;
      padding: 0.85em 1em;
      display: block;
      font-size: 20px;
      margin: 0 auto;

      .fa.fa-search {
        margin-left: 10px;
      }
    }
  }
}