.button {
  &.button-primary,
  &.button-primary:active,
  &.button-primary:visited,
  &.button-primary:link {
    border: 2px solid black;
    color: black;
    background: white;
    margin-left: 5px;
  }

  &.button-primary:hover,
  &.button-primary:focus {
    background: black;
    color: white;
  }

  &.button-banner-primary,
  &.button-banner-primary:active,
  &.button-banner-primary:visited,
  &.button-banner-primary:link {
    background: #6fcc41;
  }

  &.button-banner-primary:hover,
  &.button-banner-primary:focus {
    background: darken(#6fcc41, 10%);
  }

  &.button-banner-secondary,
  &.button-banner-secondary:active,
  &.button-banner-secondary:visited,
  &.button-banner-secondary:link {
    background: #242424;
  }

  &.button-banner-secondary:hover,
  &.button-banner-secondary:focus {
    background: darken(#242424, 10%);
  }

  &.button-register-primary,
  &.button-register-primary:active,
  &.button-register-primary:visited,
  &.button-register-primary:link {
    border: 2px solid #6fcc41;
    background: #2f2f2f;
    color: #6fcc41;
  }

  &.button-register-6wk,
  &.button-register-6wk:active,
  &.button-register-6wk:visited,
  &.button-register-6wk:link {
    border: 2px solid #fff;
    background: #242424;
    color: #ffffff;
    font-size: 1.25rem;
  }

  &.button-register-primary:hover,
  &.button-register-primary:focus {
    background: darken(#242424, 10%);
    color: #6fcc41;
  }

  &.button-close,
  &.button-close:active,
  &.button-close:visited,
  &.button-close:link {
    background: none;
    border: none;
    color: #aaacb7;
  }

  &.button-close:hover,
  &.button-close:focus {
    color: #6fcc41;
  }
}
