.register-free-trial {
    background: #2f2f2f;

    h5 {
      color: #fefefe;
    }

    p {
      color: #fefefe;
    }

    @media screen and (max-width: 63.938em) {
      text-align: center;
      padding-top: 20px !important;
      padding-bottom: 20px !important;
    }
}