a,
a:link,
a:active,
a:visited {
  color: #6fcc41;

  &:hover,
  &:focus {
    color: darken(#6fcc41, 10%);
  }
}

main {
  .content-main-title {
    border-bottom: 2px solid #7c7e7f;
  }

  .page-content-wrapper {
    .page-content {
      .page-content-inner {
        padding: 30px;

        @media screen and (max-width: 63.938em) {
          padding: 20px;
        }

        @media screen and (max-width: 39.938em) {
          padding: 10px;
        }
      }
    }
  }
}

#mc_embed_signup {
  input {
    box-shadow: none;
    height: 42.86px;

    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #4e4e4e;
      opacity: 1; /* Firefox */
    }
    &::-webkit-input-placeholder {
      color: #4e4e4e;
    }

    &:-moz-placeholder {
      color: #4e4e4e;
    }

    &::-moz-placeholder {
      color: #4e4e4e;
    }

    &:-ms-input-placeholder {
      color: #4e4e4e;
    }

    &.mce_inline_error {
      border-color: #cc4b37 !important;
      background-color: #f9ecea !important;

      &::-webkit-input-placeholder {
        color: #cc4b37 !important;
      }

      &:-moz-placeholder {
        color: #cc4b37 !important;
      }

      &::-moz-placeholder {
        color: #cc4b37 !important;
      }

      &:-ms-input-placeholder {
        color: #cc4b37 !important;
      }
    }

    @include breakpoint(large) {
      margin: 0 !important;
    }
  }

  div {
    &.mce_inline_error {
      display: none !important;
    }
  }

  #mce-responses {
    #mce-error-response {
      color: #cc4b37;
    }
  }
}

footer {
  padding: 60px;

  @media screen and (max-width: 63.938em) {
    padding: 40px 15px;
  }

  @media screen and (max-width: 39.938em) {
    padding: 20px 10px;
  }
}
