@mixin prototype-typography {
  .text-white {
    color: #fefefe;
  }

  .text-primary {
    color: #6fcc41;
  }

  .text-secondary {
    color: #242424;
  }

  .text-tertiary {
    color: #4e4e4e;
  }
}