nav#main-navigation-container {
  background: #1b1b1b;
  width: 100%;
  height: 120px;
  top: 0;
  left: 0;
  z-index: 9999;
  position: fixed;

  .navigation-wrapper {
    padding: 20px 60px;

    @include breakpoint(medium down) {
      padding-left: 15px;
      padding-right: 15px;
    }

    .navigation-logo-content {
      float: left;

      a {
        display: block;
        width: 140px;
        height: 80px;

        img {
          width: inherit;
          height: inherit;
        }
      }
    }

    .navigation-menu-content {
      float: right;

      .menu {
        li {
          a {
            line-height: 60px;

            &:link,
            &:visited,
            &:active {
              color: #fefefe;
            }

            &.active,
            &:focus,
            &:hover {
              color: #6fcc41;
            }
          }
        }
      }

      .menu-mobile {
        font-size: 30px;
        line-height: 80px;
        color: #fefefe;
      }
    }
  }
}

nav#mobile-navigation-container {
  .navigation-wrapper {
    .navigation-menu-content {
      .menu {
        li {
          a {
            outline: 0;
            padding: 20px;
            color: #fefefe;

            &:link,
            &:visited,
            &:focus,
            &:hover {
              color: #fefefe;
            }

            &.active {
              color: #6fcc41;
            }
          }
        }
      }
    }
  }
}

nav#footer-navigation-container {
  float: left;

  @include breakpoint(medium down) {
    float: none;
  }

  .navigation-wrapper {
    @include breakpoint(medium down) {
      max-width: 610px;
      margin: 0 auto;
    }

    .navigation-logo-content {
      float: left;
      padding-right: 15px;
      border-right: 1px solid #efeded;

      @media screen and (max-width: 39.938em) {
        float: none;
        padding-right: 0;
        padding-bottom: 15px;
        border-right: none;
        border-bottom: 1px solid #efeded;
      }

      a {
        display: block;
        width: 140px;
        height: 80px;

        @media screen and (max-width: 39.938em) {
          margin: 0 auto;
        }

        img {
          width: inherit;
          height: inherit;
        }
      }
    }

    .navigation-menu-content {
      float: right;

      @media screen and (max-width: 39.938em) {
        float: none;
        max-width: 360px;
        margin: 0 auto;
      }

      .menu {
        @media screen and (max-width: 39.938em) {
          display: block;
          padding-top: 10px;
          text-align: center;
        }

        li {
          a {
            line-height: 60px;

            @media screen and (max-width: 39.938em) {
              line-height: 20px;
            }

            color: #6a6a6a;

            &.active,
            &:focus,
            &:hover {
              color: #6fcc41;
            }
          }
        }
      }
    }
  }
}

.social-media-container {
  float: right;

  @include breakpoint(medium down) {
    float: none;
  }

  .social-media-wrapper {
    .social-media-content {
      .menu {
        @include breakpoint(large down) {
          width: 270px;
          margin: 0 auto;
        }

        li {
          padding: 0.7rem 1rem;

          @include breakpoint(medium down) {
            padding: 0.35rem 1rem;
          }

          a {
            line-height: 60px;
            padding: 0;

            img {
              width: 35px;
              height: 35px;
            }

            &.active,
            &:focus,
            &:hover {
              img {
                opacity: 0.8;
              }
            }
          }
        }
      }
    }
  }
}
