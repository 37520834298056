.register-six-week-challenge-form {
    background-color: #141414;
    color: #fefefe;

    form {
        button {
            margin-top: 1.5rem;

            @include breakpoint(medium down) {
                margin-top: 0;
                margin-bottom: 0;
            }
        }

        label {
            color: #fefefe;
        	
        	&.is-invalid-label {
			    color: #cc4b37;
			}
        }
    }
}