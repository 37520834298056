main#blog-main-container {
  .page-content-wrapper {
    .page-content {
      .page-content-inner {
        padding-top: 60px;
        padding-bottom: 60px;

        @media screen and (max-width: 39.938em) {
          padding-top: 20px;
          padding-bottom: 20px;
        }

        .row {
          max-width: 90rem;
        }
      }
    }
  }
}